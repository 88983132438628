<template>
  <div class="vs-promo-code bg-shade--gray px-4 py-5">
    <div class="text-medium-szm --w-medium --dark">
      {{ $t('text.register.ifAwardCode') }}
    </div>
    <div class="d-flex mt-6">
      <label for="promoCode" class="mt-6 mr-5">{{ $t('common.awardCode') }}</label>
      <v-col class="pa-0" cols="5">
        <v-text-field
          id="promoCode"
          :value="value"
          :rules="promoCodeRules"
          :disabled="disabled"
          @input="input"
          flat
          solo
          outlined
          :readonly="disabled"
          class="mt-2 --border-r-4"
          :class="{ disabled }"
        />
      </v-col>
    </div>
  </div>
</template>

<script>
import { VALIDATE_PROMOCODE } from '@/api/graphql/user'

export default {
  props: {
    value: String,
    disabled: Boolean
  },
  data () {
    return {
      hasError: false
    }
  },
  computed: {
    promoCodeRules () {
      return [
        v => !v || /^[A-Z0-9]{5}-[A-Z0-9]{5}/.test(v) || '存在しない特典コードです'
      ]
    }
  },
  methods: {
    async input (value) {
      this.hasError = true
      if (value) {
        if (this.promoCodeRules.every(func => func(value) === true)) {
          await this.$doLoading(async () => {
            await this.$showGqlError(async () => {
              await this.$apollo.mutate({
                mutation: VALIDATE_PROMOCODE,
                variables: {
                  promoCode: value
                }
              })

              this.$emit('input', value)
              this.$toast.open('特典を適用しました')
              this.hasError = false
            })
          })
        }
      } else {
        this.$emit('input', value)
        this.hasError = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vs-promo-code ::v-deep .v-input.disabled fieldset {
  background-color: #DDDDDD;
}
</style>
