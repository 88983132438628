<template>
  <div>
    <AppLayoutHeader :hide-hamburger-button="true" />
    <div class="form-container">
      <Stage :stage="2" />
      <div class="pa-3">
        <h2 class="--prm text-center">
          {{ $t('title.registerPaymentMethod') }}
        </h2>

        <p class="text-title--szm --w-medium --dark mt-5">
          {{ $t('text.register.registerPayment') }}
        </p>

        <h3 class="mt-9 text-title --w-regular --dark">{{ $t('common.creditCard') }}</h3>

        <CreditCards class="mt-5" />

        <v-form ref="form" class="mt-9">
          <label for="cardName" class="text-medium-szm --w-regular --dark">
            {{ $t('common.cardName') }}
          </label>
          <v-text-field
            id="cardName"
            v-model="form.cardHolderName"
            :rules="creditCardRules.cardHolderName"
            class="mt-2 --border-r-4"
            outlined
            solo
            flat
          ></v-text-field>

          <!-- START Card-number ------------------------>
          <label for="firstCardNumberValue" class="text-medium-szm --w-regular --dark">
            {{ $t("common.cardNumber") }}
          </label>
          <div class="d-flex justify-space-between  no-input-arrow mt-2">
            <fixed-length-input
              id="firstCardNumberValue"
              :fixedLength="4"
              v-model="form.cardNumber.firstValue"
              :rules="creditCardRules.cardNumber"
              placeholder="0000"
              type="number"
              class="mr-1 center-input"
              :next-input="$refs.secondValue"
              outlined
              solo
              flat
            />
            <fixed-length-input
              ref="secondValue"
              :fixedLength="4"
              v-model="form.cardNumber.secondValue"
              :rules="creditCardRules.cardNumber"
              placeholder="0000"
              type="tel"
              class="mr-1 center-input"
              :next-input="$refs.thirdValue"
              outlined
              solo
              flat
            />
            <fixed-length-input
              ref="thirdValue"
              :fixedLength="4"
              v-model="form.cardNumber.thirdValue"
              :rules="creditCardRules.cardNumber"
              placeholder="0000"
              type="tel"
              class="mr-1 center-input"
              :next-input="$refs.fourthValue"
              outlined
              solo
              flat
            />
            <fixed-length-input
              ref="fourthValue"
              :min-length="2"
              :max-length="4"
              v-model="form.cardNumber.fourthValue"
              :rules="creditCardRules.cardNumber4"
              placeholder="0000"
              type="tel"
              class="mr-1 center-input"
              outlined
              solo
              flat
            />
          </div>
          <!-- END Card-number -------------------------->

          <label for="monthList" class="text-medium-szm --w-regular --dark">
            {{ $t('menu.main.items.expirationDate') }}
          </label>
          <div class="d-flex mt-2">
            <v-row>
              <v-col cols="4">
                <v-select
                  id="monthList"
                  v-model="form.month"
                  :items="monthList"
                  :rules="[$rules.required]"
                  placeholder="MM"
                  outlined
                  solo
                  flat
                />
              </v-col>
              <span class="text-title --w-medium --dark py-6">/</span>
              <v-col cols="4">
                <v-select
                  v-model="form.year"
                  :items="yearList"
                  :rules="[$rules.required]"
                  placeholder="YYYY"
                  outlined
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </div>

          <label for="cvv" class="text-medium-szm --w-regular --dark">
            {{ $t('common.securityCode') }}
          </label>
          <div class="d-flex">
            <v-col cols="3" class="pa-0">
              <FixedLengthInput
                id="securityCode"
                :minLength="3"
                :maxLength="4"
                v-model="form.securityCode"
                :rules="creditCardRules.securityCode"
                placeholder="000"
                type="tel"
                class="mt-2"
                outlined
                solo
                flat
              />
            </v-col>
          </div>

          <vs-promo-code ref="vsPromoCode" v-model="form.promoCode" :disabled="promoCodeDisabled" />

          <v-btn
            elevation="0"
            class="btn-icon shadow-white mt-12 mb-5 text-heading"
            @click="commit()"
            block
            rounded
            color="btn-grad--orange"
            x-large
          >
            <span class="text-center w-100">{{ $t('buttons.next') }}</span>
            <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import Stage from '@/components/StageRegister'
import AppLayoutHeader from '@/layout/AppLayout/components/AppLayoutHeader'
import CreditCards from '@/components/icons/IconCreditCards.vue'
import FixedLengthInput from '@/components/FixedLengthInput'
import { getMonthList, getNextYearsFromCurrent } from '@/utils/get-date'
import { sessionStorageMixin } from '@/mixins/session-storage.mixin'
import commonConstants from '@/views/Register/common.constants'
import { creditCardMixin } from '@/mixins/credit-card.mixin'
import VsPromoCode from './VsPromoCode.vue'

export default {
  components: {
    AppLayoutHeader,
    Stage,
    CreditCards,
    FixedLengthInput,
    VsPromoCode
  },
  mixins: [sessionStorageMixin, creditCardMixin],
  data () {
    return {
      form: {
        cardHolderName: '',
        cardNumber: {
          firstValue: '',
          secondValue: '',
          thirdValue: '',
          fourthValue: ''
        },
        year: null,
        month: null,
        securityCode: '',
        promoCode: null
      },
      monthList: getMonthList(),
      yearList: getNextYearsFromCurrent(new Date().getFullYear(), 10)
    }
  },
  computed: {
    promoHasError () {
      return this.$refs.vsPromoCode?.hasError
    }
  },
  methods: {
    async commit () {
      if (this.promoHasError) {
        this.$toast.error('特典コードに誤りがあります。')
        return
      }
      this.setupGmo()
      if (this.$refs.form.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            const cardToken = await this.tokenize(
              this.form.cardHolderName,
              `${this.form.cardNumber.firstValue}${this.form.cardNumber.secondValue}${this.form.cardNumber.thirdValue}${this.form.cardNumber.fourthValue}`,
              `${this.form.year}${String(this.form.month).padStart(2, '0')}`,
              this.form.securityCode
            )

            const data = this.getFromStorage(commonConstants.storageKey)
            data.cardData = {
              cardToken,
              last4Digits: this.form.cardNumber.fourthValue,
              promoCode: this.form.promoCode
            }

            this.saveToStorage(commonConstants.storageKey, data)
            await this.$router.push({ name: 'register-confirmation' })
          })
        })
      }
    }
  },
  mounted () {
    const gmoScript = document.createElement('script')
    gmoScript.setAttribute('src', process.env.VUE_APP_GMO_TOKENIZE_JS_URL)
    document.head.appendChild(gmoScript)

    const saved = this.getFromStorage(commonConstants.storageKey)
    this.promoCodeDisabled = false
    if (saved.promoCodeFromUrl) {
      // set promo code from url
      this.form.promoCode = saved.promoCodeFromUrl
      this.promoCodeDisabled = true
    }
  }
}
</script>
